import React, { useState, useEffect } from 'react';
import { Select, message, Modal } from 'antd';
import propTypes from 'prop-types';
import { updateTransactionStatus } from "../../../services";
import NProgress from 'nprogress';
const { Option } = Select;
const curList=["invoiced","Deposit Charged","Remaining Payment Approved"];

const UpdateTransactionStatus = ({ Item={}, visible, onCancel, onOk }) => {
    const [status, setStatus] = useState("")
    const [list, setList] = useState(curList)

    useEffect(() => {
        if (visible) {
            setStatus("")
            const {transaction_status}=Item;
            const curStatus=transaction_status.length&&transaction_status[Item.transaction_status.length-1].status;
            let newList=[];
            if(curStatus){
                newList=curList.filter(item=>item!==curStatus);
            }else{
                newList=curList.slice(1,)
            }
            setList(()=>newList)
        }
    }, [visible])

    const handleOk = async() => {
        if(!status){
            message.error("请选择状态");
            return;
        }
        const transaction_id = Item.id;
        NProgress.start();
        updateTransactionStatus({ transaction_id, status }).then(res => {
            NProgress.done();
            if (res.status === 'success') {
                message.success("更新成功")
                onCancel();
                onOk();
            } else {
                message.error(res.message)
            }
        }).catch(e => {
            NProgress.done();
        })
    };

    const onStatusChange = (value) => {
        setStatus(value)
    }
    return (
        <Modal
            title="Update Transaction Status"
            centered
            visible={visible}
            onOk={handleOk}
            onCancel={onCancel}
        >
            <Select value={status} onChange={onStatusChange}>
                {
                    list.map(item=>{
                        return (<Option key={item}>{item}</Option>)
                    })
                }
            </Select>
        </Modal>
    );
};

UpdateTransactionStatus.propTypes = {
    visible: propTypes.bool.isRequired,
    onCancel: propTypes.func.isRequired,
};

export default UpdateTransactionStatus;
