import React, { useState, useEffect } from 'react';
import { Form, Input, Select, message, Modal } from 'antd';
import propTypes from 'prop-types';
import { updateDeliveryStatus } from "../../../services";
import NProgress from 'nprogress';
const { Option } = Select;

const ChangeDeliveryStatus = ({ Item, visible, onCancel, onOk }) => {
    const [form] = Form.useForm();

    const [DeliveryType, setDeliveryType] = useState("Delivery")
    const [status, setStatus] = useState("")
    const [status2, setStatus2] = useState("")
    const [DeliveryStatus, setDeliveryStatus] = useState("")

    useEffect(() => {
        if (visible) {
            setDeliveryStatus("")
            form && form.resetFields();
            const status = Item.delivery.length > 0 ? Item.delivery[Item.delivery.length - 1].status : "";
            const status2 = Item.transaction_status.length > 0 ? Item.transaction_status[Item.transaction_status.length - 1].status : "";
            setStatus(status)
            setStatus2(status2)
        }
    }, [visible])

    const handleOk = () => {
        const transaction_id = Item.id;
        if (status2 === "Remaining Payment Approved" && status === "Released") {
            form.validateFields().then(values => {
                updateDeliveryStatus({ transaction_id, ...values }).then(res => {
                    NProgress.done();
                    if (res.status === 'success') {
                        message.success("更新成功")
                        onCancel();
                        onOk();
                    } else {
                        message.error(res.message)
                    }
                }).catch(e => {
                    NProgress.done();
                })
            })
        } else {
            if (!DeliveryStatus) {
                message.error("请选择状态")
                return;
            };
            NProgress.start();
            updateDeliveryStatus({ transaction_id, status: DeliveryStatus }).then(res => {
                NProgress.done();
                if (res.status === 'success') {
                    message.success("更新成功")
                    onCancel();
                    onOk();
                } else {
                    message.error(res.message)
                }
            }).catch(e => {
                NProgress.done();
            })
        }
    };

    const onValuesChange = (values) => {
        if (values.status) {
            setDeliveryType(values.status)
        }
    }
    return (
        <Modal
            title="Change Delevery Status"
            centered
            visible={visible}
            onOk={handleOk}
            onCancel={onCancel}
        >
            {
                (status2 === "Remaining Payment Approved" && status === "Released") ?
                    <Form form={form} name="Change Delevery Status" onValuesChange={onValuesChange}>
                        <Form.Item name="status" label="选择配送方式：" initialValue={DeliveryType} rules={[{ required: true }]}>
                            <Select>
                                <Option value="Delivery">安排送货</Option>
                                <Option value="Pick Up">自取</Option>
                            </Select>
                        </Form.Item>
                        {
                            DeliveryType === "Delivery" &&
                            <Form.Item name="address" label="送货地址：" rules={[{ required: true, message: "请输入送货地址" }]}>
                                <Input.TextArea showCount maxLength={1000} rows={6} />
                            </Form.Item>
                        }
                    </Form> :
                    <Select value={DeliveryStatus || ""} onChange={(val) => setDeliveryStatus(val)}>
                        {
                            Item &&
                            <>
                                {(Item.listing.location === "UK" && Item.delivery.length === 0) &&
                                    <Option value="In Transit (UK-HK)">In Transit (UK-HK)</Option>
                                }
                                {(Item.transaction_status.length > 0 && Item.transaction_status[Item.transaction_status.length - 1].status === "Remaining Payment Approved") &&
                                    ((Item.delivery.length > 0 && Item.delivery[Item.delivery.length - 1].status === "In Transit (UK-HK)") || (Item.delivery.length === 0)) &&
                                    <Option value="Released">Released</Option>
                                }
                                {((Item.delivery.length > 0 && Item.delivery[Item.delivery.length - 1].status === "Pick Up") || (Item.delivery.length > 0 && Item.delivery[Item.delivery.length - 1].status === "In Transit")) &&
                                    <Option value="Delivered">Delivered</Option>
                                }
                                {(Item.delivery.length > 0 && (Item.delivery[Item.delivery.length - 1].status === "Delivery" || Item.delivery[Item.delivery.length - 1].status === "Delivery Failed")) &&
                                    <Option value="Ready to Deliver">Ready to Deliver</Option>
                                }
                                {(Item.delivery.length > 0 && Item.delivery[Item.delivery.length - 1].status === "Ready to Deliver") &&
                                    <Option value="In Transit">In Transit</Option>
                                }
                                {(Item.delivery.length > 0 && Item.delivery[Item.delivery.length - 1].status === "In Transit") &&
                                    <Option value="Delivery Failed">Delivery Failed</Option>
                                }
                            </>
                        }
                    </Select>
            }
        </Modal>
    );
};

ChangeDeliveryStatus.propTypes = {
    visible: propTypes.bool.isRequired,
    onCancel: propTypes.func.isRequired,
};

export default ChangeDeliveryStatus;
