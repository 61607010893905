import { request } from "./request";

export const login = (params) => {
	return request({
		method: 'post',
		url: '/admin/login',
		params
	}).then(result=>{
		if (result.status !== 'success') {
			throw { message: result.message };
		}
	
		if (!result.data.token) {
			throw { message: 'Token missing' };
		}
	
		saveToken(result.data.token);
		return result;
	})
}

function saveToken(token) {
	if (typeof (document) !== 'undefined') {
		document.cookie = `token=${token}`;
	}

	if (typeof (Storage) !== "undefined") {
		localStorage.setItem('token', token)
	}
}

export function logout() {
	if (typeof (document) !== 'undefined') {
		document.cookie = `token=;Max-Age=-99999999`;
	}

	if (typeof (Storage) !== "undefined") {
		localStorage.removeItem('token');
		localStorage.removeItem('favorite');
	}

	if (window && window.location) {
		window.location.href = '/';
	}
}

export function getUserDetails() {
	const token = localStorage.getItem('token') ;
	if (!token) return null;

	let info = token.split('.')[1];
	info = atob(info)
	info = JSON.parse(info);
	return info;
}

export function userFetch(){
	return ({
		headers: {
			// 'Content-Type': 'application/json',
			Authorization: getToken(),
		}
	});
}

export function getToken() {
	if (typeof (Storage) !== "undefined") {
		return localStorage.getItem('token');
	}

	if (typeof (document) !== 'undefined') {
		return getCookie('token', document.cookie);
	}
}

function getCookie(c_name, cookie) {
	if (cookie) {
		let i, x, y, ARRcookies = cookie.split(";");
		for (i = 0; i < ARRcookies.length; i++) {
			x = ARRcookies[i].substr(0, ARRcookies[i].indexOf("="));
			y = ARRcookies[i].substr(ARRcookies[i].indexOf("=") + 1);
			x = x.replace(/^\s+|\s+$/g, "");
			if (x == c_name) {
				return unescape(y);
			}
		}
	}
}

export const searchUser = (name) => {
	return request({
		method: 'post',
		url: '/dashboard/user/search',
		params:{name}
	})
}