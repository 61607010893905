//import { getUserDetails } from '../index';
import {getUserDetails} from "../index";
//import Router from 'next/router';
import React from "react";

//import { Layout } from '../../components'

function withAuth(WrappedComponent) {
	return class extends React.Component {
		constructor(props) {
			super(props);
			this.state = { user: null };
		}

		componentDidMount() {
			const user = getUserDetails();
			if (!user) window.location.replace('/auth/login');
			if (user) this.setState({ user });
		}

		render() {
			const { user } = this.state;
			{/*
			if (!user) return <Layout><div style={{ minHeight: 500 }}/></Layout>;
			*/}
			if (!user) return <div>Checking Access Permission, please wait</div>;
			return <WrappedComponent />;
		}
	};
}

export default withAuth;