import React from "react";

// reactstrap components
import {
  Badge,
  Card,
  Container,
  Table
} from "reactstrap";
import Modal from 'react-modal';
// core components
import { getAllTransactions, getOneTimePassword, cancelOrder, updateFinalPaymentAmount, priceHandler, withAuth,RegenerateInvoice } from "services/index.js";

import NProgress from 'nprogress';
import { DataTable } from "../HOC/DataTable"
import { Pagination, Input, Spin, Empty, Row, Col, Select, message,Button } from "antd";
import { SearchOutlined } from '@ant-design/icons';
import ChangeDeliveryStatus from "../components/Modal/Order/ChangeDeliveryStatus.jsx"
import UpdateTransactionStatus from "../components/Modal/Order/UpdateTransactionStatus.jsx"
import { getDepositAmount } from "../utility/utility"

const { Option } = Select;

const customStyles = {
  content: {
    width: '70%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 100,
    backgroundImage: "white"
  }
};

class Order extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loginAsUserPopup: false,
      updateStatusPopup: false,
      updateDeliveryStatusPopup: false,
      checkFinalPaymentPopup: false,
      user_id: null,
      transaction: null,
      loading: true,
      final_payment_amount: 0,
      Ranges:[]
    };
  }

  componentDidMount() { }

  onUsernameClick = (user_id) => {
    this.setState({ user_id, loginAsUserPopup: true });
  }

  onLoginAsUserClick = async (user_id) => {
    NProgress.start();
    this.setState({ loading: true });
    try {
      const res = await getOneTimePassword({ targetUserID: user_id });
      if (res.status === "success") {
        const oneTimePassword = res.data.onetime_secret;
        window.open(`${process.env.REACT_APP_FRONTEND_URL}/admin-login?token=${oneTimePassword}&uid=${user_id}`, "_blank");
      }else{
        message.error(res.message)
      }
    } catch (e) {
      console.log(e.message);
    }
    this.setState({ loading: false, loginAsUserPopup: false });
    NProgress.done();
  }

  cancelOrder = async () => {
    const {transaction}=this.state;
    const transaction_id = transaction.id;
    NProgress.start();
    this.setState({ loading: true });
    await cancelOrder(transaction_id);
    this.setState({ loading: false, cancelOrderPopup: false, transaction: null })
    this.props.getList();
    NProgress.done();
  }

  updateFinalPaymentAmount = async (amount) => {
    const {transaction}=this.state;
    const transaction_id = transaction.id;
    if (amount > 0) {
      const amount_to_db = amount * 100;
      NProgress.start();
      this.setState({ loading: true });
      await updateFinalPaymentAmount({ transaction_id, amount: amount_to_db });
      this.setState({ loading: false, checkFinalPaymentPopup: false, transaction: null, final_payment_amount: 0 });
      this.props.getList();
      NProgress.done();
    }
  }
  keywordSearch = (e) => {
    const { setFieldValue, search } = this.props;
    setFieldValue({ keywords: e.target.value })
    search();
  }
  handleRangeSelect=(Ranges)=>{
    const { setFieldValue } = this.props;
    setFieldValue({ category:Ranges })
    this.setState({Ranges})
  }
  handleDeliveryStatus=(item) => {
    if(item.status==='invoiced') return;
    const status=item.delivery.length > 0 && item.delivery[item.delivery.length - 1].status;
    if(status==="Delivered") return;
    this.setState({ transaction: item, updateDeliveryStatusPopup: true })
  }
  onCreateInvoice=(id)=>{
    this.setState({ loading: true });
    RegenerateInvoice(id).then(res=>{
      this.setState({ loading: false });
      this.props.getList();
    }).catch(()=>{
      this.setState({ loading: false });
    })
  }
  render() {
    const { pageChange, total, page: { current, pageSize }, dataList, pageSizeOptions, loading,getList } = this.props;
    const {transaction,updateDeliveryStatusPopup,updateStatusPopup}=this.state;
    return (
      <>
        {/* <Header /> */}
        <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
          <Container fluid>
            <div className="header-body"><h1>Orders</h1></div>
          </Container>
        </div>
        {/* Page content */}
        <Container className="mt--7" fluid>
          {/* lgoin as user confirm popup */}
          {/*
          <Modal
            isOpen={this.state.loginAsUserPopup}
            onAfterOpen={() => { }}
            onRequestClose={() => { }}
            style={customStyles}
            contentLabel="loginAsUser"
          >
            <h2 style={{ textAlign: "center", marginBottom: "10px" }}> Confirm Login As User ? </h2>
            {this.state.loading ?
              <h3 style={{ textAlign: "center", color: "red" }}>Loading, please wait</h3>
              :
              <div>
                <button style={{ cursor: "pointer", backgroundColor: "white", border: "1px solid black", borderRadius: "12px", width: "20%", marginLeft: "15%", marginRight: "15%" }} onClick={() => this.setState({ user_id: null, loginAsUserPopup: false })}>NO</button>
                <button style={{ cursor: "pointer", backgroundColor: "white", border: "1px solid black", borderRadius: "12px", width: "20%", marginLeft: "15%", marginRight: "15%" }} onClick={() => this.onLoginAsUserClick(this.state.user_id)}>YES</button>
              </div>
            }
          </Modal>
          */}
          {/* update status select popup */}
          <UpdateTransactionStatus
            Item={transaction}
            visible={updateStatusPopup}
            onCancel={()=>this.setState({updateStatusPopup: false})}
            onOk={getList}/>
          {/* cancel order popup */}
          {transaction &&
            <Modal
              isOpen={this.state.cancelOrderPopup}
              onAfterOpen={() => { }}
              onRequestClose={() => { }}
              style={customStyles}
              contentLabel="updateStatus"
            >
              <h2 style={{ textAlign: "center", marginBottom: "10px" }}> Confirm Cancel Order ? Transaction Number: {transaction.id}</h2>
              {this.state.loading ?
                <h3 style={{ textAlign: "center", color: "red" }}>Loading, please wait</h3>
                :
                <div>
                  <button className="popup-yes-no-btn" onClick={() => this.setState({ transaction: null, cancelOrderPopup: false })}>BACK</button>
                  <button className="popup-yes-no-btn" onClick={() => this.cancelOrder()}>CONFIRM</button>
                </div>
              }
            </Modal>
          }
          {/* update delivery status select popup */}
          <ChangeDeliveryStatus
            Item={transaction}
            visible={updateDeliveryStatusPopup}
            onCancel={()=>this.setState({updateDeliveryStatusPopup: false})}
            onOk={getList}
          />
          {transaction &&
            <Modal
              isOpen={this.state.checkFinalPaymentPopup}
              onAfterOpen={() => { }}
              onRequestClose={() => { }}
              style={customStyles}
              contentLabel="checkFinalPayment"
            >
              <h2 style={{ textAlign: "center", marginBottom: "10px" }}> Payment Prove of Transaction Number: {transaction.id}</h2>
              {Array.isArray(transaction.final_payment) ? transaction.final_payment.map((item, index) =>
                <div style={{ textAlign: "center" }} key={index}>
                  <a href={item.url} target="_blank">{item.name}</a>
                </div>)
                : <div style={{ textAlign: "center" }}><a href={transaction.final_payment}>Payment Proof</a></div>}
              <div style={{ textAlign: "center" }}>
                <span>Total Received Amount (HKD): </span><input style={{ textAlign: "center" }} type="number" value={this.state.final_payment_amount} onChange={(e) => this.setState({ final_payment_amount: e.target.value })} />
              </div>
              <div style={{ marginTop: "10px" }}>
                <button className="popup-yes-no-btn" onClick={() => this.setState({ transaction: null, checkFinalPaymentPopup: false })}>BACK</button>
                <button className="popup-yes-no-btn" onClick={() => this.updateFinalPaymentAmount(this.state.final_payment_amount)}>CONFIRM</button>
              </div>
            </Modal>
          }
          {/* Table */}
          <Card className="shadow tableBox">
            <Row className='mt24 mb24 ml12' gutter={[16, 16]}>
              <Col span={8}>
                <label>搜索范围：</label>
                <Select
                  mode="multiple"
                  allowClear
                  size="large"
                  placeholder="Please select"
                  onChange={this.handleRangeSelect}
                  className="rangesStyle"
                >
                  <Option value="username">USERNAME</Option>
                  <Option value="ref_no">REF NO</Option>
                  <Option value="wines">WINES</Option>
                </Select>
              </Col>
              <Col span={8}>
                <label>关键字：</label>
                <Input onPressEnter={this.keywordSearch} size="large" placeholder="keyword" prefix={<SearchOutlined />} />
              </Col>
            </Row>
            <Table className="align-items-center table-flush" responsive>
              <thead className="thead-light">
                <tr>
                  <th scope="col">Order matched date</th>
                  <th scope="col">Status</th>
                  <th sccope="col">Delivery Status</th>
                  <th scope="col">Username</th>
                  <th scope="col">Wines</th>
                  <th scope="col">Invoice</th>
                  <th scope="col">Total price</th>
                  <th scope="col">Number of case</th>
                  <th scope="col">Bottle per case</th>
                  <th scope="col">Deposit</th>
                  <th scope="col">Discount</th>
                  <th scope="col">Final Payment</th>
                  <th scope="col">Control</th>
                  <th scope="col">REF NO</th>
                </tr>
              </thead>
              <tbody>
                {
                  !loading && dataList.length ?
                    <>
                      {

                        dataList.map(item =>
                          <tr key={item.id}>
                            <td>{item.created_at.split(" ")[0]}</td>
                            <td style={{ color: "red" }}>{item.transaction_status.length > 0 ? item.transaction_status[item.transaction_status.length - 1].status : item.status === "completed" ? "Deposit Charged" : item.status}</td>
                            {item.status === "admin-cancelled" ?
                              <td style={{ color: "red" }}>Order Cancelled</td>
                              : <td style={{ cursor: "pointer", color: "red" }} onClick={()=>this.handleDeliveryStatus(item)}>{item.delivery.length > 0 ? item.delivery[item.delivery.length - 1].status : ("Processing (" + (item.listing ? item.listing.location : "") + ")")}</td>
                            }
                            <td style={{ cursor: "pointer" }} onClick={() => this.onUsernameClick(item.user_id)}>{item.user.username}</td>
                            <td>{item.listing.wines_options.name_display_EN + " " + item.listing.wines_options.vintage}</td>
                            <td>{item.invoice?<a href={item.invoice} target="_blank" download>Download Invoice</a>:<><div>No invoice yet</div><button className="btn btn-secondary mt2" onClick={()=>this.onCreateInvoice(item.id)}>Create Invoice</button></>}</td>
                            <td>{priceHandler(item.price * item.number_of_case * item.bottle_per_case * item.commission < item.min_commission ? item.price * item.number_of_case * item.bottle_per_case + item.min_commission + item.shipping_fee : item.price * item.number_of_case * item.bottle_per_case * (1 + item.commission) + (item.listing.location === "UK" && item.bottle_per_case <= 3 ? item.shipping_fee === 0 ? 30000 * item.number_of_case : 30000 * 0.8 * item.number_of_case : 0))}</td>
                            <td>{item.number_of_case}</td>
                            <td>{item.bottle_per_case}</td>
                            <td>
                              <Badge color="" className="badge-dot">
                                {
                                  (item.payment !== "null" && item.payment !== null && item.payment !== "[]" && JSON.parse(item.payment)[0]?.object === "charge") ?
                                      <i className="bg-success" />
                                      : <i className="bg-warning" />
                                }
                              </Badge>
                              {
                                (item.payment !== "null" && item.payment !== null && item.payment !=="[]" &&  JSON.parse(item.payment)[0]?.object === "charge") ?
                                  priceHandler(getDepositAmount(JSON.parse(item.payment))): "HKD $ 0"
                                }
                            </td>
                            <td>{priceHandler(item.discount)}</td>
                            <td>
                              <Badge color="" className="badge-dot">
                                {item.final_payment ? <i className="bg-success" /> : <i className="bg-warning" />}
                              </Badge>
                              {item.final_payment ? <span style={{ cursor: "pointer" }} onClick={() => this.setState({ transaction: item, checkFinalPaymentPopup: true, final_payment_amount: item.final_payment_amount / 100 })}>PAID</span> : "PENDING"}
                            </td>
                            {item.status === "admin-cancelled" ?
                              <td />
                              : <td className="text-right">
                                <button className="btn btn-secondary" onClick={() => this.setState({ transaction: item, cancelOrderPopup: true })}>Cancel Order</button>
                                <button className="btn btn-secondary" onClick={() => this.setState({ transaction: item, updateStatusPopup: true })}>Update</button>
                              </td>
                            }
                            <td>{item.ref_no}</td>
                          </tr>
                        )
                      }
                    </>
                    :
                    <tr className="table_loading">
                      {/* 13对应的是表头的数量，不然不居中 */}
                      <td colSpan={13}>
                        {
                          loading ?
                            <div className="textCenter">
                              <Spin spinning={true} />
                            </div>
                            : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        }
                      </td>
                    </tr>
                }
              </tbody>
            </Table>
            <div className="mt20 mb20">
              {
                dataList.length ?
                  <Pagination
                    showSizeChanger
                    pageSizeOptions={pageSizeOptions}
                    onChange={pageChange}
                    current={current}
                    pageSize={pageSize}
                    total={total}
                  /> : null
              }
            </div>
          </Card>
        </Container>
      </>
    );
  }
}

export default withAuth(DataTable(getAllTransactions)(Order));
