// import HTTPApi from '@services';
// const { downloadFileApi } = HTTPApi.file;

/**
 * Return ellipsis of a given string
 * @param {string} text
 * @param {number} size
 */
export const ellipsis = (text, size) => {
  return `${text
    .split(' ')
    .slice(0, size)
    .join(' ')}...`;
};
/**
 * @description: 判断是否为空对象或者空数组或者空Date
 * @param {object} obj  要校验的对象
 * @author: luguojin
 * @return {boolean} 如果存在空就返回true
 */
export function isEmptyObject(obj) {
  if (obj === 'null') return true;
  if (obj === 'undefined') return true;
  const type = obj.constructor;
  //检验数组
  if (type === Array && obj.length === 0) {
    return true;
  }
  //检验对象
  if (type === Object && Object.keys(obj).length === 0) {
    return true;
  }
  //检验Date类型
  if (type === Date && obj == 'Invalid Date') {
    return true;
  }
  return false;
}

/**
 * @description: 过滤一个对象里面的空值，空对象或者空数组也过滤掉
 * @param {object} obj  要过滤的对象
 * @author: luguojin
 * @return {object} 过滤后的对象
 */
export function filterEmptyObj(obj) {
  let newObj = {};
  for (const key in obj) {
    if ((obj[key] && !isEmptyObject(obj[key])) || obj[key] === 0 || typeof obj[key] === 'boolean') {
      newObj[key] = obj[key]
    }
  }
  return newObj;
}

/**
 * 格式化文件大小单位
 * @param {number} size  地址
 * @author: luguojin
 * @returns {String} 格式化后的string
 */
export function formatterSizeUnit(size) {
  if (size) {
    const result = parseInt(size);
    if (result < 1024) {
      return `${result} B`;
    } if (result < 1024 * 1024) {
      return `${parseInt(result / 1024)} KB`;
    } if (result < 1024 * 1024 * 1024) {
      return `${parseInt(result / (1024 * 1024))} MB`;
    }
    return `${parseInt(result / (1024 * 1024 * 1024))} GB`;
  }
}

/**
 * 上传的图片尺寸校验
 *
 * @param  {[File]} file 图片文件流
 * @param  {[Array]} imgSize 校验列的图片尺寸
 * @author: luguojin
 * @return {[Boolean]} 
 */
export function ImgSizeCheck(file, imgSize) {
  return new Promise((resolve, reject) => {
    let width = imgSize[0];
    let height = imgSize[1];
    let _URL = window.URL || window.webkitURL;
    let img = new Image();
    img.src = _URL.createObjectURL(file);
    img.onload = () => {
      let valid = img.width == width && img.height == height;
      valid ? resolve(true) : resolve(false);
    }
  })
}

/**
 * 判断是否是图片类型
 *
 * @param  {[String]} url 链接
 * @author: luguojin
 * @return {[Boolean]} 
 */
export function judgeIsImg(url,imgType){
  const ImgType=imgType||['png','jpg','jpeg'];
  const Suffix=getFileSuffix(url);
  return ImgType.includes(Suffix.toLowerCase());
}

/**
 * 提取文件后缀
 * @param url
 * @author: luguojin
 * @returns {string}
 */
 export function getFileSuffix(url) {
  if (!url || typeof url !== 'string') return '';
  const arr=url.split('.');
  return arr[arr.length-1];
}

/**
 * 提取文件名
 * @param path
 * @author: luguojin
 * @returns {string}
 */
 export function getFileName(path) {
  if(!path) return;
  var index = path.lastIndexOf("/"); // lastIndexOf("/")  找到最后一个  /  的位置
  var fileName = path.substr(index + 1); // substr() 截取剩余的字符，即得文件名xxx.doc
  return fileName;
 }

 /**
 * 使用a标签下载文件
 * @param path 路径
 * @param fileName 要下载的文件名，可以不传
  */
 export function aTagDownload(path,fileName){
  if(!path) return;
  const link = document.createElement('a');
  fileName&&(link.download = fileName);
  link.style.display = 'none';
  link.href = path;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link); // 下载完成移除元素
}

 /**
 * 使用a标签下载文件
 * @param {[String]} url 路径(需要完整路径)
  */
  // export function downloadFile(url){
  //   if(!url) return;
  //   const fileName=getFileName(url);
  //   const imgType=['png','jpg','jpeg','gif','pdf','bmp','tif','pcx','tga','exif','fpx','svg','psd','cdr','pcd'];
  //   if(judgeIsImg(url,imgType)){
  //     downloadFileApi({url}).then(buf=>{
  //       let blob = new Blob([buf]);
  //       let href = window.URL.createObjectURL(blob);
  //       aTagDownload(href,fileName);
  //       window.URL.revokeObjectURL(href) // 释放掉blob对象
  //     })
  //   }else{
  //     aTagDownload(url,fileName)
  //   }
  // }
  export function getDepositAmount(payment){
    let deposit = 0;
    try{
        payment.forEach(item=>{
          deposit += item.amount;
        }
      );
    }
    catch (e) {
      // code for error handeling
                  console.log(e)
      }
    return deposit;
  }
  
