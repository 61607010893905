/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Index from "views/Index.js";
import Order from "views/Order.js";
import Bid from "views/Bid.js";
import Wallet from "views/Wallet.js";
import Points from "views/Points.js";
import Coupon from "views/Coupon.js";
import CouponHistory from "views/CouponHistory.js";
//import Profile from "views/examples/Profile.js";
//import Maps from "views/examples/Maps.js";
//import Register from "views/examples/Register.js";
import Login from "views/login/index.js";
//import Tables from "views/examples/Tables.js";
//import Icons from "views/examples/Icons.js";

var routes = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: Index,
    layout: "/admin"
  },
  /*
  {
    path: "/icons",
    name: "Icons",
    icon: "ni ni-planet text-blue",
    component: Icons,
    layout: "/admin"
  },
  {
    path: "/maps",
    name: "Maps",
    icon: "ni ni-pin-3 text-orange",
    component: Maps,
    layout: "/admin"
  },
  {
    path: "/user-profile",
    name: "User Profile",
    icon: "ni ni-single-02 text-yellow",
    component: Profile,
    layout: "/admin"
  },
  {
    path: "/tables",
    name: "Tables",
    icon: "ni ni-bullet-list-67 text-red",
    component: Tables,
    layout: "/admin"
  },
  */
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth"
  },
  /*
  {
    path: "/register",
    name: "Register",
    icon: "ni ni-circle-08 text-pink",
    component: Register,
    layout: "/auth"
  }
  */
  {
    path: "/orders",
    name: "Orders",
    icon: "ni ni-bullet-list-67 text-red",
    component: Order,
    layout: "/admin"
  },
  {
    path: "/bids",
    name: "Bids",
    icon: "ni ni-money-coins text-red",
    component: Bid,
    layout: "/admin"
  },
  {
    path: "/wallets",
    name: "Wallets",
    icon: "ni ni-money-coins text-red",
    component: Wallet,
    layout: "/admin"
  },
  {
    path: "/points",
    name: "Points",
    icon: "ni ni-money-coins text-red",
    component: Points,
    layout: "/admin"
  },
  {
    path: "/coupons",
    name: "Coupons",
    icon: "ni ni-money-coins text-red",
    component: Coupon,
    layout: "/admin"
  },
  {
    path: "/couponHistory",
    name: "CouponHistory",
    icon: "ni ni-money-coins text-red",
    component: CouponHistory,
    layout: "/admin"
  }
];
export default routes;
