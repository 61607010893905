import React, { Component } from 'react';
import { filterEmptyObj } from "../utility/utility"
import NProgress from 'nprogress';

/**
 * @description: table的数据流 高阶组件
 * @param {func} fn  接口的函数
 * @param {func} firstRequest  是否需要高阶函数的第一次列表请求
 * @param {func} PageSize  每页条数
 * @param {func} pageSizeOptions  指定每页可以显示多少条
 * @author: luguojin
 * @return {WrappedComponent} 返回的是组件
 */
export const DataTable = (fn, firstRequest = true, PageSize = 20, pageSizeOptions) => WrappedComponent =>
    class extends Component {
        constructor(props) {
            super(props);
            this.state = {
                dataList: [],//总数据
                page: {//分页
                    current: 1,//当前页
                    pageSize: PageSize,//每页条数
                },
                fn,
                pageSizeOptions: pageSizeOptions || [ 20, 30, 40, 50],//指定每页可以显示多少条
                total: 0,//总数
                fields: {},//请求所需参数
                FormDom: {},//获取组件实例dom
                params: {},//过滤后的参数
                loading: false,
            };
        }
        componentDidMount() {
            if (firstRequest) this.getList()
        }
        //列表请求方法
        getList = (val = this.state.fields) => {
            this.state.params = filterEmptyObj(val);
            let { page: { current, pageSize }, params } = this.state;
            const filterPage = {
                offset: (current - 1) * pageSize,
                limit: pageSize
            }
            this.state.dataList = [];
            this.setState({ loading: true });
            NProgress.start();
            //接收子组件使用注解传过来的方法，发送请求获取数据并保存
            this.state.fn && this.state.fn({ ...filterPage, ...params }).then((res) => {
                NProgress.done();
                const { data = [], totalCount = 0 } = res || {};
                this.state.dataList = data;
                this.state.total = res.message;
                this.setState({ loading: false })
            }).catch(e => {
                console.log(e)
                NProgress.done();
                this.setState({ loading: false })
            })
        }

        // 每页条数改变事件
        pageSizeChange = (current,pageSize) => {
            this.state.page.current = 1;
            this.state.page.pageSize = pageSize;
            this.getList();
        }

        //页码以及每页条数改变事件
        pageChange = (current, pageSize) => {
            this.state.page.current = current || this.state.page.current;
            this.state.page.pageSize = pageSize || this.state.page.pageSize;
            this.getList();
        }

        updateData = (data) => {
            this.setState({ dataList: data })
        }

        //排序
        SortChange(name, sortVal) {
            this.state.fields.fieldSort = sortVal.order === 'ascend' ? name : '';//ascend为升序,与后端商量好排序的字段叫fieldSort，值为要排序的字段名
            this.getList();
        }
        //默认查询方法
        search = (current = 1) => {
            this.state.page.current = current;
            this.getList();
        }
        //设置请求参数
        setFieldValue = (params, callback) => {
            this.state.fields = {
                ...this.state.fields,
                ...params
            };
            callback && callback();
        }
        resetFieldValue = () => {
            this.state.fields = {}
        }
        render() {
            const props = {
                ...this.props,
                ...this.state,
                pageChange: this.pageChange,
                updateData: this.updateData,
                pageSizeChange: this.pageSizeChange,
                search: this.search,
                setFieldValue: this.setFieldValue,
                resetFieldValue: this.resetFieldValue,
                getList: this.getList,
                onRef: (node) => this.refDom = node,
                updateApi: (api, cb) => {
                    this.setState({ fn: api }, cb);
                }
            }
            return <WrappedComponent
                {...props}
            />;
        }
    };