import React from "react";

// reactstrap components
import {
  Card,
  Table,
  Container,
} from "reactstrap";
import Modal from 'react-modal';
import { getAllWalletHistorys, updateWalletActionStatus, updateWalletActionAmount, priceHandler, withAuth, fileUpload, searchUser } from "services/index.js";

import NProgress from 'nprogress';
import { addWalletRecord } from "services";
import { DataTable } from "../HOC/DataTable";
import { Pagination, Input, Spin, Empty, Row, Col, Select } from "antd";
import { SearchOutlined } from '@ant-design/icons';

const { Option } = Select;

const customStyles = {
  content: {
    width: '50%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 100,
    backgroundImage: "white"
  }
};

let searchTimeout;

class Wallet extends React.Component {
  constructor() {
    super();

    this.state = {
      actionPopup: false,
      selectedActions: [],
      user_id: null,
      loading: false,
      amount: null,
      fileName: null,
      fileURL: [],
      Ranges:[]
    };
  }

  componentDidMount() { }

  onUpdateStatusClick = async (id, status) => {
    NProgress.start();
    this.setState({ loading: true });
    try {
      const res = await updateWalletActionStatus({ id, status });
      if (res.status === "success") {
        console.log("status updated");
      }
    } catch (e) {
      console.log(e.message);
    }
    this.setState({ loading: false, actionPopup: false, selectedActions: null });
    this.props.getList();
    NProgress.done();
  }

  onUpdateAmountClick = async (id) => {
    NProgress.start();
    this.setState({ loading: true });
    const { amount } = this.state;
    try {
      const res = await updateWalletActionAmount({ id, amount });
      if (res.status === "success") {
        console.log("amount updated");
      }
    } catch (e) {
      console.log(e.message);
    }
    this.setState({ loading: false, actionModifyPopup: false, selectedActions: null, amount: 0 });
    this.props.getList();
    NProgress.done();
  }

  onConfirmNewRecord = async () => {
    NProgress.start();
    const { targetUser, amount, remark, fileURL } = this.state;
    if (!targetUser || !amount) {
      return;
    }
    
    let fileURL_json = "[]";
    if (fileURL && fileURL.length >= 1) {
      fileURL_json = JSON.stringify(fileURL);
    }

    const action_option = document.getElementById("wallet-action");
    if (action_option.selectedIndex != null && action_option.selectedIndex >= 0) {
      const type = action_option.value;
      if (type === "Credit" || type === "Debit") {
        const response = await addWalletRecord({ user_id: targetUser.id, amount, remark, type, fileURL: fileURL_json });
        if (response && response.status === "success") {
          this.setState({
            newActionPopup: false,
            amount: null,
            userlist: null,
            targetUser: null,
            remark: null,
            searchWord: null,
            fileName: null,
            fileURL: []
          });
          this.props.getList();
        }
      } else {
        return;
      }
      NProgress.done();
    }
    NProgress.done();
  }

  handleTopupUpload = async (file) => {
    const { loading, targetUser } = this.state;
    const id = targetUser.id;
    if (!file[0] || loading || !targetUser) return;
    this.setState({ loading: true });

    try {
      const { fileName, fileURL } = this.state;
      let new_fileName = fileName;
      if (new_fileName === null) {
        new_fileName = file[0].name;
      } else {
        new_fileName += ", " + file[0].name;
      }
      const result = await fileUpload(file, id);
      let new_fileURL = fileURL;
      new_fileURL.push(result.data.data);
      if (new_fileURL !== null) {
        this.setState({ fileURL: new_fileURL, fileName: new_fileName, loading: false });
      }
    } catch (e) {
      this.setState({ loading: false });
    }
  }

  searchUser = async (name) => {
    this.setState({ searchWord: name });
    if (searchTimeout) clearTimeout(searchTimeout);
    searchTimeout = setTimeout(async () => {
      NProgress.start();
      const response = await searchUser(name);
      if (response && response.status === "success") {
        this.setState({ userlist: response.data });
      }
      NProgress.done();
    }, 500);
  }


  keywordSearch = (e) => {
    const { setFieldValue, search } = this.props;
    setFieldValue({ keywords: e.target.value })
    search();
  }
  handleRangeSelect = (Ranges) => {
    const { setFieldValue } = this.props;
    setFieldValue({ category: Ranges })
    this.setState({ Ranges })
  }

  render() {
    const { pageChange, total, page: { current, pageSize }, dataList, pageSizeOptions, loading } = this.props;
    return (
      <>
        {/* <Header /> */}
        <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
          <Container fluid>
            <div className="header-body"><h1>Wallets</h1></div>
            <button onClick={() => this.setState({ newActionPopup: true })}>Add new record</button>
          </Container>
        </div>
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Modal
            isOpen={this.state.actionPopup}
            onAfterOpen={() => { }}
            onRequestClose={() => { }}
            style={customStyles}
            contentLabel="update status"
          >
            <h2 style={{ textAlign: "center", marginBottom: "10px" }}> Confirm the following amount ? </h2>
            {this.state.selectedActions && <h3 style={{ textAlign: "center", color: "red" }}>{priceHandler(this.state.selectedActions.amount)}</h3>}
            {this.state.loading ?
              <h3 style={{ textAlign: "center", color: "red" }}>Loading, please wait</h3>
              :
              <div style={{ marginTop: "10px" }}>
                <button style={{ cursor: "pointer", backgroundColor: "white", border: "1px solid black", borderRadius: "12px", width: "20%", marginLeft: "15%", marginRight: "15%" }} onClick={() => this.onUpdateStatusClick(this.state.selectedActions.id, "Declined")}>Decline</button>
                <button style={{ cursor: "pointer", backgroundColor: "white", border: "1px solid black", borderRadius: "12px", width: "20%", marginLeft: "15%", marginRight: "15%" }} onClick={() => this.onUpdateStatusClick(this.state.selectedActions.id, "Confirmed")}>Confirm</button>
                <div style={{ textAlign: "center", marginTop: "20px" }}>
                  <button style={{ cursor: "pointer", backgroundColor: "white", border: "1px solid black", width: "40%" }} onClick={() => this.setState({ selectedActions: [], actionPopup: false })}>Back</button>
                </div>
              </div>
            }
          </Modal>
          <Modal
            isOpen={this.state.actionModifyPopup}
            onAfterOpen={() => { }}
            onRequestClose={() => { }}
            style={customStyles}
            contentLabel="modify action"
          >
            <h2 style={{ textAlign: "center", marginBottom: "10px" }}> Please input the modified amount </h2>
            {this.state.selectedActions && <div style={{ textAlign: "center" }}><input style={{ textAlign: "center" }} type="number" value={this.state.amount!==null ? this.state.amount / 100 : this.state.selectedActions.amount / 100} onChange={(e) => this.setState({ amount: e.target.value * 100 })} /></div>}
            {this.state.loading ?
              <h3 style={{ textAlign: "center", color: "red" }}>Loading, please wait</h3>
              :
              <div style={{ marginTop: "10px" }}>
                <button style={{ cursor: "pointer", backgroundColor: "white", border: "1px solid black", borderRadius: "12px", width: "20%", marginLeft: "15%", marginRight: "15%" }} onClick={() => this.setState({ amount: null, selectedActions: [], actionModifyPopup: false })}>Back</button>
                <button style={{ cursor: "pointer", backgroundColor: "white", border: "1px solid black", borderRadius: "12px", width: "20%", marginLeft: "15%", marginRight: "15%" }} onClick={() => this.onUpdateAmountClick(this.state.selectedActions.id)}>Update</button>
              </div>
            }
          </Modal>
          <Modal
            isOpen={this.state.newActionPopup}
            onAfterOpen={() => { }}
            onRequestClose={() => { }}
            style={customStyles}
            contentLabel="new actions"
          >
            <div style={{ textAlign: "center" }}>
              <h2 style={{ textAlign: "center", marginBottom: "10px" }}> Please enter the user email </h2>
              <input style={{ textAlign: "center", width: "80%" }} value={this.state.searchWord} onChange={(e) => this.searchUser(e.target.value)} />
              {this.state.userlist && this.state.userlist.map(item =>
                <div style={{ cursor: "pointer" }} onClick={() => this.setState({ targetUser: item, searchWord: item.username })}>{item.username}</div>
              )}
              <h2 style={{ textAlign: "center", marginBottom: "10px" }}> Please enter the amount </h2>
              <input style={{ textAlign: "center" }} type="number" value={this.state.amount ? this.state.amount / 100 : 0} onChange={(e) => this.setState({ amount: e.target.value * 100 })} />
              <h2 style={{ textAlign: "center", marginBottom: "10px" }}> Please select the action </h2>
              <div style={{ textAlign: "center", marginBottom: "20px" }}>
                <select id="wallet-action" className="popup-select" name="wallet-action">
                  <option value="Credit">Credit</option>
                  <option value="Debit">Debit</option>
                </select>
              </div>
              <h2 style={{ textAlign: "center", marginBottom: "10px" }}> Please enter the reamrk </h2>
              <textarea id="w3review" name="w3review" rows="3" cols="50" onChange={(e) => this.setState({ remark: e.target.value })}></textarea>
              <h2 style={{ textAlign: "center", marginBottom: "10px" }}> Please upload the proof (if any) </h2>
              <input
                style={{ display: "none" }}
                type="file"
                id="wallet_topup"
                accept="image/*"
                onChange={(e) => this.handleTopupUpload(e.target.files)}
              />
              <label for="wallet_topup" className="btn-w-base smaller w-inline-block" style={{ cursor: "pointer", margin: "10px 0 0 0 " }}>Select File</label>
              {this.state.fileName !== null && <div className="info-info" style={{ justifyContent: "center" }}> Uploaded file: {this.state.fileName} </div>}
            </div>
            {this.state.loading ?
              <h3 style={{ textAlign: "center", color: "red" }}>Loading, please wait</h3>
              :
              <div style={{ marginTop: "10px" }}>
                <button style={{ cursor: "pointer", backgroundColor: "white", border: "1px solid black", borderRadius: "12px", width: "20%", marginLeft: "15%", marginRight: "15%" }} onClick={() => this.setState({ userlist: [], targetUser: null, newActionPopup: false })}>Back</button>
                <button style={{ cursor: "pointer", backgroundColor: "white", border: "1px solid black", borderRadius: "12px", width: "20%", marginLeft: "15%", marginRight: "15%" }} onClick={() => this.onConfirmNewRecord()}>Confirm</button>
              </div>
            }
          </Modal>
          <Modal
            isOpen={this.state.newActionPopup}
            onAfterOpen={() => { }}
            onRequestClose={() => { }}
            style={customStyles}
            contentLabel="new actions"
          >
            <div style={{ textAlign: "center" }}>
              <h2 style={{ textAlign: "center", marginBottom: "10px" }}> Please enter the user email </h2>
              <input style={{ textAlign: "center", width: "80%" }} value={this.state.searchWord} onChange={(e) => this.searchUser(e.target.value)} />
              {this.state.userlist && this.state.userlist.map(item =>
                <div style={{ cursor: "pointer" }} onClick={() => this.setState({ targetUser: item, searchWord: item.username })}>{item.username}</div>
              )}
              <h2 style={{ textAlign: "center", marginBottom: "10px" }}> Please enter the amount </h2>
              <input style={{ textAlign: "center" }} type="number" value={this.state.amount ? this.state.amount / 100 : 0} onChange={(e) => this.setState({ amount: e.target.value * 100 })} />
              <h2 style={{ textAlign: "center", marginBottom: "10px" }}> Please select the action </h2>
              <div style={{ textAlign: "center", marginBottom: "20px" }}>
                <select id="wallet-action" className="popup-select" name="wallet-action">
                  <option value="Credit">Credit</option>
                  <option value="Debit">Debit</option>
                </select>
              </div>
              <h2 style={{ textAlign: "center", marginBottom: "10px" }}> Please enter the reamrk </h2>
              <textarea id="w3review" name="w3review" rows="3" cols="50" onChange={(e) => this.setState({ remark: e.target.value })}></textarea>
              <h2 style={{ textAlign: "center", marginBottom: "10px" }}> Please upload the proof (if any) </h2>
              <input
                style={{ display: "none" }}
                type="file"
                id="wallet_topup"
                accept="image/*"
                onChange={(e) => this.handleTopupUpload(e.target.files)}
              />
              <label for="wallet_topup" className="btn-w-base smaller w-inline-block" style={{ cursor: "pointer", margin: "10px 0 0 0 " }}>Select File</label>
              {this.state.fileName !== null && <div className="info-info" style={{ justifyContent: "center" }}> Uploaded file: {this.state.fileName} </div>}
            </div>
            {this.state.loading ?
              <h3 style={{ textAlign: "center", color: "red" }}>Loading, please wait</h3>
              :
              <div style={{ marginTop: "10px" }}>
                <button style={{ cursor: "pointer", backgroundColor: "white", border: "1px solid black", borderRadius: "12px", width: "20%", marginLeft: "15%", marginRight: "15%" }} onClick={() => this.setState({ userlist: [], targetUser: null, newActionPopup: false })}>Back</button>
                <button style={{ cursor: "pointer", backgroundColor: "white", border: "1px solid black", borderRadius: "12px", width: "20%", marginLeft: "15%", marginRight: "15%" }} onClick={() => this.onConfirmNewRecord()}>Confirm</button>
              </div>
            }
          </Modal>
          <Modal
            isOpen={this.state.deletePopup}
            onAfterOpen={() => { }}
            onRequestClose={() => { }}
            style={customStyles}
            contentLabel="delete actions"
          >
            <h2 style={{ textAlign: "center", marginBottom: "10px" }}> Confirm Delete wallet action #{this.state.selectedActions && this.state.selectedActions.id} ?</h2>
            {this.state.loading ?
              <h3 style={{ textAlign: "center", color: "red" }}>Loading, please wait</h3>
              :
              <div style={{ marginTop: "10px" }}>
                <button style={{ cursor: "pointer", backgroundColor: "white", border: "1px solid black", borderRadius: "12px", width: "20%", marginLeft: "15%", marginRight: "15%" }} onClick={() => this.setState({ selectedActions: [], deletePopup: false })}>Back</button>
                <button style={{ cursor: "pointer", backgroundColor: "white", border: "1px solid black", borderRadius: "12px", width: "20%", marginLeft: "15%", marginRight: "15%" }} onClick={() => this.onDeleteWalletActions(this.state.selectedActions.id)}>Confirm</button>
              </div>
            }
          </Modal>
          {/* Table */}
          <Card className="shadow tableBox">
            <Row className='mt24 mb24 ml12' gutter={[16, 16]}>
              <Col span={8}>
                <label>搜索范围：</label>
                <Select
                  mode="multiple"
                  allowClear
                  size="large"
                  placeholder="Please select"
                  onChange={this.handleRangeSelect}
                  className="rangesStyle"
                >
                  <Option value="username">USERNAME</Option>
                  <Option value="ref_no">REF NO</Option>
                </Select>
              </Col>
              <Col span={8}>
                <label>关键字：</label>
                <Input onPressEnter={this.keywordSearch} size="large" placeholder="keyword" prefix={<SearchOutlined />} />
              </Col>
            </Row>
            <Table className="align-items-center table-flush" responsive>
              <thead className="thead-light">
                <tr>
                  <th scope="col">REF NO</th>
                  <th scope="col">USERNAME</th>
                  <th scope="col">AMOUNT (HKD)</th>
                  <th scope="col">PROVIDER</th>
                  <th scope="col">TYPE</th>
                  <th scope="col">REMARK</th>
                  <th scope="col">STATUS</th>
                  <th scope="col">SOURCE</th>
                  <th scope="col">APPROVED BY</th>
                  <th scope="col">UPDATED AT</th>
                  <th scope="col">ACTION</th>
                </tr>
              </thead>
              <tbody>
                {
                  !loading && dataList.length ?
                    <>
                      {dataList.map(item =>
                        <tr key={item.id}>
                          <td>{item.ref_no}</td>
                          <td>{item.user.username}</td>
                          <td>{priceHandler(item.amount)}</td>
                          <td>{item.provider}</td>
                          <td>{item.type}</td>
                          <td>{item.remark}</td>
                          <td>{item.status === "Declined" ?
                            <span style={{ color: "red" }}>Declined</span> :
                            item.status === "Confirmed" ?
                              <span style={{ color: "green" }}>Confirmed</span> :
                              <span style={{ color: "grey" }}>{item.status}</span>
                          }</td>
                          <td>
                            {(item.source !== null && item.source !== "") ?
                              JSON.parse(item.source).map((source, index) =>
                                <div key={index}>
                                  {
                                    source === "Stripe" ?
                                      <div>Pay by Stripe</div> :
                                      <div><a target="_blank" href={source}>{"File " + (index + 1)}</a></div>
                                  }
                                </div>
                              )
                              : <span>source</span>}
                          </td>
                          <td>{item.handled_by}</td>
                          <td>{item.updated_at}</td>
                          <td>
                            {item.status === "Pending" &&
                              <div>
                                <button className="btn btn-secondary" onClick={() => this.setState({ selectedActions: item, actionPopup: true })}>Update Status</button>
                                {item.type === "Credit" && <button className="btn btn-secondary" onClick={() => this.setState({ selectedActions: item, actionModifyPopup: true })}>Edit</button>}
                              </div>
                            }
                          </td>
                        </tr>
                      )}
                    </>
                    :
                    <tr className="table_loading">
                      {/* 10对应的是表头的数量，不然不居中 */}
                      <td colSpan={11}>
                        {
                          loading ?
                            <div className="textCenter">
                              <Spin spinning={true} />
                            </div>
                            : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        }
                      </td>
                    </tr>
                }
              </tbody>
            </Table>
            <div className="mt20 mb20">
              {
                dataList.length ?
                  <Pagination
                    showSizeChanger
                    pageSizeOptions={pageSizeOptions}
                    onChange={pageChange}
                    current={current}
                    pageSize={pageSize}
                    total={total}
                  /> : null
              }
            </div>
          </Card>
        </Container>
      </>
    );
  }
}

export default withAuth(DataTable(getAllWalletHistorys)(Wallet));
