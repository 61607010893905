import React from "react";

// reactstrap components
import {
  Card,
  Table,
  Container
} from "reactstrap";
import { getAllCouponHistory, priceHandler, withAuth } from "services/index.js";
import { DataTable } from "../HOC/DataTable";
import { Pagination, Input, Spin, Empty, Row, Col, Select } from "antd";
import { SearchOutlined } from '@ant-design/icons';

class CouponHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      actionPopup: false,
      selectedActions: [],
      user_id: null,
      loading: false
    };
  }

  componentDidMount() { }

  keywordSearch = (e) => {
    const { setFieldValue, search } = this.props;
    setFieldValue({ keywords: e.target.value })
    search();
  }

  render() {
    const { pageChange, total, page: { current, pageSize }, dataList, pageSizeOptions, loading } = this.props;
    return (
      <>
        {/* <Header /> */}
        <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
          <Container fluid>
            <div className="header-body"><h1>Coupon History</h1></div>
          </Container>
        </div>
        {/* Page content */}
        <Container className="mt--7" fluid>
          {/* Table */}
          <Card className="shadow tableBox">
            <Row className='mt24 mb24 ml12' gutter={[16, 16]}>
              <Col span={8}>
                <label>关键字：</label>
                <Input onPressEnter={this.keywordSearch} size="large" placeholder="keyword" prefix={<SearchOutlined />} />
              </Col>
            </Row>
            <Table className="align-items-center table-flush" responsive>
              <thead className="thead-light">
                <tr>
                  <th scope="col">ID</th>
                  <th scope="col">USERNAME</th>
                  <th scope="col">COUPON CODE</th>
                  <th scope="col">AMOUNT (HKD)</th>
                  <th scope="col">OPERATION</th>
                  <th scope="col">STATUS</th>
                  <th scope="col">HANDLED BY</th>
                  <th scope="col">TYPE</th>
                  <th scope="col">CREATED AT</th>
                  <th scope="col">UPDATED AT</th>
                  {/* <th scope="col">ACTION</th> */}
                </tr>
              </thead>
              <tbody>
                {
                  !loading && dataList.length ?
                    <>
                      {
                        dataList.map(item =>
                          <tr key={item.id}>
                            <td>{item.id}</td>
                            <td>{item.user.username}</td>
                            <td>{item.coupon.code}</td>
                            <td>{priceHandler(item.amount)}</td>
                            <td>{item.action}</td>
                            <td>{item.status === "redeemed" ?
                              <span style={{ color: "green" }}>redeemed</span> :
                              <span style={{ color: "grey" }}>{item.status}</span>
                            }</td>
                            <td>{item.handled_by}</td>
                            <td>{item.type}</td>
                            <td>{item.created_at}</td>
                            <td>{item.updated_at}</td>
                          </tr>
                        )
                      }
                    </>
                    :
                    <tr className="table_loading">
                      {/* 10对应的是表头的数量，不然不居中 */}
                      <td colSpan={10}>
                        {
                          loading ?
                            <div className="textCenter">
                              <Spin spinning={true} />
                            </div>
                            : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        }
                      </td>
                    </tr>
                }
              </tbody>
            </Table>
            <div className="mt20 mb20">
              {
                dataList.length ?
                  <Pagination
                    showSizeChanger
                    pageSizeOptions={pageSizeOptions}
                    onChange={pageChange}
                    current={current}
                    pageSize={pageSize}
                    total={total}
                  /> : null
              }
            </div>
          </Card>
        </Container>
      </>
    );
  }
}

export default withAuth(DataTable(getAllCouponHistory)(CouponHistory));
