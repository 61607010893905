/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col
} from "reactstrap";

import { login, getToken } from 'services';
import { message } from "antd";

class Login extends React.Component {

    constructor(props) {
		super(props);
		this.state = { loading: false, success: false };
	}

	onLoginClick = async () => {
		this.setState({ loading: true });

		const username = document.getElementById('login-username').value;
		const password = document.getElementById('login-password').value;

		if (!username || !password) {
			this.setState({ loading: false });
			return message.error('Please fill in all information');
		}

		try {
			await login({ username, password });
            this.props.history.push("/");
			this.setState({ success: true, loading: false });
		} catch (e) {
			message.error(e.message);
			this.setState({ loading: false });
		}
	}

	EnterKeyListener = (e) => {
		if (e.key === 'Enter') {
			this.onLoginClick();
		}
  }
    
  render() {
    const { success, loading } = this.state;
    const { onLoginClick } = this;
    return (
      <>
        <Col lg="5" md="7">
          <Card className="bg-secondary shadow border-0">
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center text-muted mb-4">
                <large>StockVins Admin Panel</large>
              </div>
              <Form role="form">
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-single-02" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Username" type="text" maxLength={25} id="login-username" required/>
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Password" type="password" maxLength={25} id="login-password" onKeyDown={this.EnterKeyListener} required/>
                  </InputGroup>
                </FormGroup>
                <div className="text-center">
                  <Button className="my-4" color="primary" type="button" disabled={loading} onClick={onLoginClick} defaultValue="Sign Up" data-wait="Please wait...">
					{loading ? 'Loading...' : 'Sign in'}
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </>
    );
  }
}

export default Login;
