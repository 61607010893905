import { request } from "./request";

export const getAllWallets = () => {
	return request({
		method: 'get',
		url: '/dashboard/wallet',
	})
}

export const getAllWalletHistorys = (params) => {
	return request({
		method: 'post',
		url: '/dashboard/wallet/history',
		params
	})
}

export const updateWalletActionStatus = ({ id, status }) => {
	return request({
		method: 'post',
		url: `/dashboard/wallet/action/status/${id}`,
		params:{status}
	})
}

export const updateWalletActionAmount = ({ id, amount }) => {
	return request({
		method: 'post',
		url:`/dashboard/wallet/action/modify/${id}`,
		params:{amount}
	})
}

export const getAllPointsHistorys = (params) => {
	return request({
		method: 'post',
		url: '/dashboard/points/history',
		params
	})
}

export const addPointsRecord = (params) => {
	return request({
		method: 'post',
		url: '/dashboard/points/action/add',
		params
	})
}

export const addWalletRecord = (params) => {
	const { user_id, amount, remark, type, fileURL } = params;
	return request({
		method: 'post',
		url: '/dashboard/wallet/action/add',
		params:{user_id, amount, remark, type, url:fileURL}
	})
}

export const deletePointsActions = (id) => {
	return request({
		method: 'get',
		url: `/dashboard/points/action/delete/${id}`,
	})
}
