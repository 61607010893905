import { request } from "./request";
import { getToken } from "./user";

const fileUpload = (file, id) => {
	return request({
		method: 'post',
		url: `/dashboard/file/upload/${id}`,
		params: { file: file[0] },
		config: {
			formData: true
		},
	})
}
function fileUpload2(file) {
	const newToken=getToken();
	let headers={};
    if(newToken){
        headers.Authorization="Bearer " + newToken;
    }
	return request({
		method: 'post',
		url: '/private-transaction/upload',
		params:{file:file[0]},
		config: {
		  formData:true
		},
		axiosConfig:{
			baseURL: process.env.REACT_APP_WINE_PORTAL_API,
			headers
		}
	})
}
export {fileUpload,fileUpload2}