import { request } from "./request";

export const getStat = () => {
	return request({
		method: 'get',
		url: '/dashboard/stat',
	})
}

export const getOneTimePassword = (params) => {
	return request({
		method: 'post',
		url: '/admin/getonetimepassword',
		params
	})
}
