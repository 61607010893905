import React from "react";

// reactstrap components
import {
  Card,
  Table,
  Container
} from "reactstrap";
import Modal from 'react-modal';
import { getAllPointsHistorys, addPointsRecord, priceHandler, withAuth, searchUser, deletePointsActions } from "services/index.js";

import NProgress from 'nprogress';
import { DataTable } from "../HOC/DataTable";
import { Pagination, Input, Spin, Empty, Row, Col, Select } from "antd";
import { SearchOutlined } from '@ant-design/icons';

const { Option } = Select;

const customStyles = {
  content: {
    width: '50%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 100,
    backgroundImage: "white"
  }
};

let searchTimeout;

class Points extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      newActionPopup: false,
      selectedActions: null,
      user_id: null,
      loading: false,
      amount: null,
      userlist: null,
      targetUser: null,
      searchWord: null,
      remark: null,
      deletePopup: false,
      Ranges:[]
    };
  }

  componentDidMount() { }

  searchUser = async (name) => {
    if (searchTimeout) clearTimeout(searchTimeout);
    searchTimeout = setTimeout(async () => {
      this.setState({ searchWord: name });
      NProgress.start();
      const response = await searchUser(name);
      if (response && response.status === "success") {
        this.setState({ userlist: response.data });
      }
      NProgress.done();
    }, 500);
  }

  onConfirmNewRecord = async () => {
    NProgress.start();
    const { targetUser, amount, remark } = this.state;
    if (!targetUser || !amount) {
      return;
    }
    const action_option = document.getElementById("points-action");
    if (action_option.selectedIndex != null && action_option.selectedIndex >= 0) {
      const type = action_option.value;
      if (type === "Credit" || type === "Debit") {
        const response = await addPointsRecord({ user_id: targetUser.id, amount, remark, type });
        if (response && response.status === "success") {
          this.setState({
            newActionPopup: false,
            amount: null,
            userlist: null,
            targetUser: null,
            remark: null,
            searchWord: null
          });
          this.props.getList();
        }
      } else {
        return;
      }
      NProgress.done();
    }
    NProgress.done();
  }

  onDeletePointsActions = async (id) => {
    NProgress.start();
    const response = await deletePointsActions(id);
    if (response && response.status === "success") {
      this.props.getList();
    }
    NProgress.done();
  }

  keywordSearch = (e) => {
    const { setFieldValue, search } = this.props;
    setFieldValue({ keywords: e.target.value })
    search();
  }
  handleRangeSelect = (Ranges) => {
    const { setFieldValue } = this.props;
    setFieldValue({ category: Ranges })
    this.setState({ Ranges })
  }

  render() {
    const { pageChange, total, page: { current, pageSize }, dataList, pageSizeOptions, loading } = this.props;
    return (
      <>
        {/* <Header /> */}
        <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
          <Container fluid>
            <div className="header-body"><h1>Points</h1></div>
            <button onClick={() => this.setState({ newActionPopup: true })}>Add new record</button>
          </Container>
        </div>
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Modal
            isOpen={this.state.deletePopup}
            onAfterOpen={() => { }}
            onRequestClose={() => { }}
            style={customStyles}
            contentLabel="delete actions"
          >
            <h2 style={{ textAlign: "center", marginBottom: "10px" }}> Confirm Delete points action #{this.state.selectedActions && this.state.selectedActions.id} ?</h2>
            {this.state.loading ?
              <h3 style={{ textAlign: "center", color: "red" }}>Loading, please wait</h3>
              :
              <div style={{ marginTop: "10px" }}>
                <button style={{ cursor: "pointer", backgroundColor: "white", border: "1px solid black", borderRadius: "12px", width: "20%", marginLeft: "15%", marginRight: "15%" }} onClick={() => this.setState({ selectedActions: [], deletePopup: false })}>Back</button>
                <button style={{ cursor: "pointer", backgroundColor: "white", border: "1px solid black", borderRadius: "12px", width: "20%", marginLeft: "15%", marginRight: "15%" }} onClick={() => this.onDeletePointsActions(this.state.selectedActions.id)}>Confirm</button>
              </div>
            }
          </Modal>
          <Modal
            isOpen={this.state.newActionPopup}
            onAfterOpen={() => { }}
            onRequestClose={() => { }}
            style={customStyles}
            contentLabel="new actions"
          >
            <div style={{ textAlign: "center" }}>
              <h2 style={{ textAlign: "center", marginBottom: "10px" }}> Please enter the user email </h2>
              <input style={{ textAlign: "center", width: "80%" }} value={this.state.searchWord} onChange={(e) => this.searchUser(e.target.value)} />
              {this.state.userlist && this.state.userlist.map(item =>
                <div style={{ cursor: "pointer" }} onClick={() => this.setState({ targetUser: item, searchWord: item.username })}>{item.username}</div>
              )}
              <h2 style={{ textAlign: "center", marginBottom: "10px" }}> Please enter the amount </h2>
              <input style={{ textAlign: "center" }} type="number" value={this.state.amount ? this.state.amount / 100 : 0} onChange={(e) => this.setState({ amount: e.target.value * 100 })} />
              <h2 style={{ textAlign: "center", marginBottom: "10px" }}> Please select the action </h2>
              <div style={{ textAlign: "center", marginBottom: "20px" }}>
                <select id="points-action" className="popup-select" name="points-action">
                  <option value="Credit">Credit</option>
                  <option value="Debit">Debit</option>
                </select>
              </div>
              <h2 style={{ textAlign: "center", marginBottom: "10px" }}> Please enter the reamrk </h2>
              <textarea id="w3review" name="w3review" rows="3" cols="50" onChange={(e) => this.setState({ remark: e.target.value })}></textarea>
            </div>
            {this.state.loading ?
              <h3 style={{ textAlign: "center", color: "red" }}>Loading, please wait</h3>
              :
              <div style={{ marginTop: "10px" }}>
                <button style={{ cursor: "pointer", backgroundColor: "white", border: "1px solid black", borderRadius: "12px", width: "20%", marginLeft: "15%", marginRight: "15%" }} onClick={() => this.setState({ userlist: [], targetUser: null, newActionPopup: false })}>Back</button>
                <button style={{ cursor: "pointer", backgroundColor: "white", border: "1px solid black", borderRadius: "12px", width: "20%", marginLeft: "15%", marginRight: "15%" }} onClick={() => this.onConfirmNewRecord()}>Confirm</button>
              </div>
            }
          </Modal>
          {/* Table */}
          <Card className="shadow tableBox">
            <Row className='mt24 mb24 ml12' gutter={[16, 16]}>
              <Col span={8}>
                <label>搜索范围：</label>
                <Select
                  mode="multiple"
                  allowClear
                  size="large"
                  placeholder="Please select"
                  onChange={this.handleRangeSelect}
                  className="rangesStyle"
                >
                  <Option value="username">USERNAME</Option>
                  <Option value="ref_no">REF NO</Option>
                </Select>
              </Col>
              <Col span={8}>
                <label>关键字：</label>
                <Input onPressEnter={this.keywordSearch} size="large" placeholder="keyword" prefix={<SearchOutlined />} />
              </Col>
            </Row>
            <Table className="align-items-center table-flush" responsive>
              <thead className="thead-light">
                <tr>
                  <th scope="col">REF NO</th>
                  <th scope="col">USERNAME</th>
                  <th scope="col">AMOUNT (HKD)</th>
                  <th scope="col">STATUS</th>
                  <th scope="col">REMARK</th>
                  <th scope="col">APPROVED BY</th>
                  <th scope="col">UPDATED AT</th>
                  <th scope="col">ACTION</th>
                </tr>
              </thead>
              <tbody>
                {
                  !loading && dataList.length ?
                    <>
                      {dataList.map(item =>
                        <tr key={item.id}>
                          <td>{item.ref_no}</td>
                          <td>{item.user.username}</td>
                          <td>{priceHandler(item.amount)}</td>
                          <td>{item.status === "Declined" ?
                            <span style={{ color: "red" }}>Declined</span> :
                            item.status === "Approved" ?
                              <span style={{ color: "green" }}>Approved</span> :
                              <span style={{ color: "grey" }}>{item.status}</span>
                          }</td>
                          <td>{(item.remark !== null && item.remark !== "") ?
                            <div>{item.remark}</div>
                            : <span></span>}</td>
                          <td>{item.handled_by}</td>
                          <td>{item.updated_at}</td>
                          <td>
                            {item.status === "Confirmed" &&
                              <div>
                                <button className="btn btn-secondary" onClick={() => this.setState({ selectedActions: item, deletePopup: true })}>Delete</button>
                              </div>
                            }
                          </td>
                        </tr>
                      )}
                    </>
                    :
                    <tr className="table_loading">
                      {/* 10对应的是表头的数量，不然不居中 */}
                      <td colSpan={8}>
                        {
                          loading ?
                            <div className="textCenter">
                              <Spin spinning={true} />
                            </div>
                            : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        }
                      </td>
                    </tr>
                }
              </tbody>
            </Table>
            <div className="mt20 mb20">
              {
                dataList.length ?
                  <Pagination
                    showSizeChanger
                    pageSizeOptions={pageSizeOptions}
                    onChange={pageChange}
                    current={current}
                    pageSize={pageSize}
                    total={total}
                  /> : null
              }
            </div>
          </Card>
        </Container>
      </>
    );
  }
}

export default withAuth(DataTable(getAllPointsHistorys)(Points));
