import React from "react";

// reactstrap components
import {
  Badge,
  Card,
  Table,
  Container,
} from "reactstrap";
import Modal from 'react-modal';
import { getAllBids, getOneTimePassword, priceHandler, withAuth } from "services/index.js";

import NProgress from 'nprogress';
import { DataTable } from "../HOC/DataTable";
import Sort from "../components/Sort/Sort.jsx"
import { Pagination, Input, Spin, Empty, Row, Col, Select } from "antd";
import { SearchOutlined } from '@ant-design/icons';
import { getDepositAmount } from "../utility/utility"
const { Option } = Select;

const customStyles = {
  content: {
    width: '50%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 100,
    backgroundImage: "white"
  }
};

class Bid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loginAsUserPopup: false,
      user_id: null,
      loading: false,
      startAtSort: "",
      expiresArSort: "",
      Ranges: []
    };
  }

  componentDidMount() { }


  onUsernameClick = (user_id) => {
    this.setState({ user_id, loginAsUserPopup: true });
  }

  onLoginAsUserClick = async (user_id) => {
    NProgress.start();
    this.setState({ loading: true });
    try {
      const res = await getOneTimePassword({ targetUserID: user_id });
      if (res.status === "success") {
        const oneTimePassword = res.data.onetime_secret;
        window.open(`${process.env.REACT_APP_FRONTEND_URL}/admin-login?token=${oneTimePassword}&uid=${user_id}`, "_blank");
      }
    } catch (e) {
      console.log(e.message);
    }
    this.setState({ loading: false, loginAsUserPopup: false });
    NProgress.done();
  }

  isJsonParsable = text => {
    try {
      JSON.parse(text);
    } catch (e) {
      return false;
    }
    return true;
  }
  keywordSearch = (e) => {
    const { setFieldValue, search } = this.props;
    setFieldValue({ keywords: e.target.value })
    search();
  }
  handleRangeSelect = (Ranges) => {
    const { setFieldValue } = this.props;
    setFieldValue({ category: Ranges })
    this.setState({ Ranges })
  }

  render() {
    const { isJsonParsable } = this;
    const { pageChange, total, page: { current, pageSize }, dataList, pageSizeOptions, loading } = this.props;
    const { startAtSort, expiresArSort } = this.state;
    return (
      <>
        {/* <Header /> */}
        <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
          <Container fluid>
            <div className="header-body"><h1>Bids</h1></div>
          </Container>
        </div>
        {/* Page content */}
        <Container className="mt--7" fluid>
          {/*
          <Modal
            isOpen={this.state.loginAsUserPopup}
            onAfterOpen={() => { }}
            onRequestClose={() => { }}
            style={customStyles}
            contentLabel="loginAsUser"
          >
            <h2 style={{ textAlign: "center", marginBottom: "10px" }}> Confirm Login As User ? </h2>
            {this.state.loading ?
              <h3 style={{ textAlign: "center", color: "red" }}>Loading, please wait</h3>
              :
              <div>
                <button style={{ cursor: "pointer", backgroundColor: "white", border: "1px solid black", borderRadius: "12px", width: "20%", marginLeft: "15%", marginRight: "15%" }} onClick={() => this.setState({ user_id: null, loginAsUserPopup: false })}>NO</button>
                <button style={{ cursor: "pointer", backgroundColor: "white", border: "1px solid black", borderRadius: "12px", width: "20%", marginLeft: "15%", marginRight: "15%" }} onClick={() => this.onLoginAsUserClick(this.state.user_id)}>YES</button>
              </div>
            }
          </Modal>
          */}
          {/* Table */}
          <Card className="shadow tableBox">
            <Row className='mt24 mb24 ml12' gutter={[16, 16]}>
              <Col span={8}>
                <label>搜索范围：</label>
                <Select
                  mode="multiple"
                  allowClear
                  size="large"
                  placeholder="Please select"
                  onChange={this.handleRangeSelect}
                  className="rangesStyle"
                >
                  <Option value="username">USERNAME</Option>
                  <Option value="ref_no">REF NO</Option>
                  <Option value="wines">WINES</Option>
                </Select>
              </Col>
              <Col span={8}>
                <label>关键字：</label>
                <Input onPressEnter={this.keywordSearch} size="large" placeholder="keyword" prefix={<SearchOutlined />} />
              </Col>
            </Row>
            <Table className="align-items-center table-flush" responsive>
              <thead className="thead-light">
                <tr>
                  <th scope="col">REF NO</th>
                  <th scope="col">USERNAME</th>
                  <th scope="col">WINES</th>
                  <th scope="col">PRICE</th>
                  <th scope="col">LAST MARKET PRICE</th>
                  <th scope="col">CASE</th>
                  <th scope="col">
                    START AT
                    <Sort value={startAtSort} onChange={(val) => { this.setState({ startAtSort: val, expiresArSort: "" }); }} />
                  </th>
                  <th scope="col">
                    EXPIRES AT
                    <Sort value={expiresArSort} onChange={(val) => { this.setState({ expiresArSort: val, startAtSort: "" }); }} />
                  </th>
                  <th scope="col">BOTTLE SIZE</th>
                  <th scope="col">SOURCE</th>
                  <th scope="col">LOCATION</th>
                  <th scope="col">SOURCE ID	</th>
                  <th scope="col">DEPOSIT</th>
                  <th scope="col">DISCOUNT</th>
                  <th scope="col">STATUS</th>
                </tr>
              </thead>
              <tbody>
                {
                  !loading && dataList.length ?
                    <>
                      {
                        dataList.map(item =>
                          <tr key={item.id}>
                            <td>{item.ref_no}</td>
                            <td style={{ cursor: "pointer" }} onClick={() => this.onUsernameClick(item.user_id)}>{item.user.username}</td>
                            <td>{item.listing === null ? "Listing item not matched" : item.listing.wines_options.name_display_EN + " " + item.listing.wines_options.vintage}</td>
                            <td>{priceHandler(item.price)}</td>
                            <td>{item.listing === null ? "Listing item not matched" : priceHandler(item.listing.wines_options.last_market_price)}</td>
                            <td>{item.number_of_case + " case x " + item.bottle_per_case + " btl"}</td>
                            <td>{item.created_at}</td>
                            <td>{item.expires_at}</td>
                            <td>{item.listing === null ? "Listing item not matched" : item.listing.wines_options.bottle_size}</td>
                            <td>{item.listing === null ? "Listing item not matched" : item.listing.wines_options.source}</td>
                            <td>{item.listing === null ? "Listing item not matched" : (item.listing.wines_options.wines.country ? item.listing.wines_options.wines.country : "") + (item.listing.wines_options.wines.region ? " - " + item.listing.wines_options.wines.region : "") + (item.listing.wines_options.wines.sub_region ? " - " + item.listing.wines_options.wines.sub_region : "")}</td>
                            <td>{item.listing === null ? "Listing item not matched" : item.listing.source_id}</td>
                            <td>
                              <Badge color="" className="badge-dot">
                                {
                                  (item.payment !== "null" && item.payment !== null && item.payment !== "[]" && JSON.parse(item.payment)[0]?.object === "charge") ?
                                      <i className="bg-success" />
                                      : <i className="bg-warning" />
                                }
                              </Badge>
                              {
                                (item.payment !== "null" && item.payment !== null && item.payment !=="[]" &&  JSON.parse(item.payment)[0]?.object === "charge") ?
                                  priceHandler(getDepositAmount(JSON.parse(item.payment))): "HKD $ 0"
                                }
                            </td>
                            <td>{priceHandler(item.discount)}</td>
                            <td>
                              {item.status === "closed" ?
                                (item.payment !== "null" && item.payment !== null && isJsonParsable(item.payment)) ? (
                                  (('object' in JSON.parse(item.payment) && JSON.parse(item.payment).object === "refund") ||
                                    ('data' in JSON.parse(item.payment) && JSON.parse(item.payment).data.object === "refund")) ?
                                    <span style={{ color: "red" }}>Bid Deposit Returned</span>
                                    : <span style={{ color: "red" }}>{item.status}</span>
                                ) : <span style={{ color: "red" }}>{item.status}</span>
                                : <span style={{ color: "green" }}>{item.status}</span>}
                            </td>
                          </tr>
                        )
                      }
                    </>
                    :
                    <tr className="table_loading">
                      {/* 10对应的是表头的数量，不然不居中 */}
                      <td colSpan={15}>
                        {
                          loading ?
                            <div className="textCenter">
                              <Spin spinning={true} />
                            </div>
                            : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        }
                      </td>
                    </tr>
                }
              </tbody>
            </Table>
            <div className="mt20 mb20">
              {
                dataList.length ?
                  <Pagination
                    showSizeChanger
                    pageSizeOptions={pageSizeOptions}
                    onChange={pageChange}
                    current={current}
                    pageSize={pageSize}
                    total={total}
                  /> : null
              }
            </div>
          </Card>
        </Container>
      </>
    );
  }
}

export default withAuth(DataTable(getAllBids)(Bid));
