import { request } from "./request";

export const getAllTransactions = (params) => {
	return request({
		method: 'post',
		url: '/dashboard/transaction',
		params
	})
}

export const updateTransactionStatus = (params) => {
	return request({
		method: 'post',
		url: '/dashboard/transaction/status',
		params
	})
}

export const updateDeliveryStatus = (params) => {
	return request({
		method: 'post',
		url: '/dashboard/transaction/deliverystatus',
		params
	})
}

export const cancelOrder = (id) => {
	return request({
		method: 'post',
		url: `/dashboard/transaction/cancel/${id}`,
	})
}

export const updateFinalPaymentAmount = (params) => {
	return request({
		method: 'post',
		url: '/dashboard/transaction/finalpaymentamount',
		params
	})
}
export const RegenerateInvoice = (id) => {
	return request({
		method: 'get',
		url: `/dashboard/regenerateInvoice/${id}`
	})
}
