import axios from 'axios'
import { message as Message } from 'antd';
import config from './config';
import { httpRequestList } from './clearHttpRequest';
import logOut from "./logOut"
const { CancelToken } = axios;

/**
 * @description: 读取Token
 * @parameter {*}
 * @return {String}
 */
const getToken = () => {
    if (typeof Storage !== "undefined") {
        return localStorage.getItem("token");
    }
}

class checkErrorMessageClass {
    // 初始化队列
    constructor() {
        this.queue = []; // 队列
        this.timeout = 5000; // 清除时间 ms
        this.currentPathName = '';
    }
    // 在队列中查找是否存在对应key
    searchErrorCode(key) {
        return this.queue.includes(key);
    }
    // 新的message
    addMessage(errorCode,firstFunc) {
        // 如果该errorCode不在队列中则处理
        const pathname = document.location.pathname;
        if (pathname !== this.currentPathName) {
            this.clearQueue();
            this.currentPathName = pathname;
        }
        if (!this.searchErrorCode(errorCode)) {

            firstFunc&&firstFunc();//优先执行一个函数(为了防抖，需要在这里执行)

            let desc = errorCode;
            if (typeof errorCode === 'number') {
                desc = errorCode
            }

            Message.error(desc);
            this.queue.push(errorCode);
            // 5s后清除该条数据
            setTimeout(() => {
                this.removeFirstMessage()
            }, this.timeout);
        }
    }
    removeFirstMessage() {
        this.queue.length && this.queue.shift();
    }
    clearQueue() {
        this.queue = [];
    }
}
const checkErrorMessage = new checkErrorMessageClass();

function Index() {
    const cancelToken = new CancelToken((c) => {
        httpRequestList.push(c)
    })
    const service = axios.create({
        // 默认headers
        /*headers: {
          'Cache-Control': 'no-cache'
        },*/
        baseURL: config.baseURL,
        // withCredentials: true, // 携带cookies
        timeout: 30000, // request timeout
        cancelToken,
        validateStatus: function (status) {
            return status < 500; // Reject only if the status code is greater than or equal to 500
        }
    })

    // request interceptor
    service.interceptors.request.use(
        config => {
            return config
        },
        error => {
            return Promise.reject(error)
        }
    )

    // response interceptor
    service.interceptors.response.use(
        response => {
            return response
        },
        error => {
            const {status,request}=error;
            if(status===500){
                checkErrorMessage.addMessage(request.statusText||"unknown error");
            }else{
                checkErrorMessage.addMessage(request.statusText||"unknown error");
            }
            return Promise.reject(error)
        }
    )

    /**
     * @description: 发起请求
     * @parameter method 请求方法
     * @parameter url 请求地址
     * @parameter params 要发送的数据
     * @parameter config 配置
     * @parameter axiosConfig Axios配置项
     * @return {Promise<never>|Promise<AxiosResponse<T>>}
     */
    const requestProcessor = (method, url, params = {}, headers, config, axiosConfig) => {
        // 鉴权
        const token = getToken()
        if (token) {
            headers['Authorization'] = token;
        }
        // 表单序列化
        if (config.formData) {
            const fd = new FormData();
            for (let key in params) {
                fd.append(key, params[key])
            }
            params = fd
        }

        // 根据请求类型发起请求
        switch (method.toUpperCase()) {
            case 'GET':
                return service.get(url, {
                    params,
                    headers,
                    ...axiosConfig,
                })
            case 'POST':
                return service.post(url, params, {
                    headers,
                    ...axiosConfig,
                })
            case 'DELETE':
                return service.delete(url, {
                    params,
                    headers,
                    ...axiosConfig,
                })
            case 'PUT':
                return service.put(url, params, {
                    headers,
                    ...axiosConfig,
                })
            default:
                return Promise.reject(new Error(`${method} 方法无效，请用正确的请求方法`))
        }
    }

    this.request = async ({ method, url, params, headers = {}, config = {}, axiosConfig = {} }) => {

        try {
            const response = await requestProcessor(method, url, params, headers, config, axiosConfig)
            // 此处可以再次拦截
            if (axiosConfig&&axiosConfig.pass) return response.data;
            const { status, message, errorCode,desc } = response.data;
            if (status === 'success' || status === 0) {//成功的状态返回
                return response.data;
            } else if (status === "expired" || (errorCode && errorCode === 10401)) {//登录过期,cas返回的code:10401，也表示是登录过期
                logOut();//退出登录
                return Promise.reject(message||desc);
            } else if (status === 'unauthorized' || (errorCode && errorCode === 11002)) {//未登录
                if (typeof (window.location) !== 'undefined') {
                    const tips="Please login first";
                    const { pathname, search } = window.location;
                    if(pathname==="/login") return;//登录页不需要提示和跳转
                    checkErrorMessage.addMessage(tips, () => {
                        let path = "/login?callback=" + pathname;
                        if (search) path = path + search;
                        window.location.href = path;
                    })
                }
                return Promise.reject(message||desc);
            } else {
                checkErrorMessage.addMessage(message||desc)
                return Promise.reject(message||desc);
            }

            
        } catch (error) {
            throw error
        } finally {
        }

    }
}

export { config, getToken }
export const { request } = new Index()
export default Index