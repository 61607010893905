import { request } from "./request";

export const getAllCoupon = (params) => {
	return request({
		method: 'post',
		url: '/dashboard/coupon/all',
		params
	})
}

export const getAllCouponHistory = (params) => {
	return request({
		method: 'post',
		url: '/dashboard/coupon/history',
		params
	})
}

export const addCoupon = (params) => {
	return request({
		method: 'post',
		url: '/dashboard/coupon/add',
		params
	})
}

export const editCouponStatus = (id, status) => {
	return request({
		method: 'post',
		url: `/dashboard/coupon/status/${id}`,
		params:{status}
	})
}