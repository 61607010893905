import React from "react";

// reactstrap components
import {
  Card,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Table,
  Container
} from "reactstrap";
import Modal from 'react-modal';
import { getAllCoupon, priceHandler, withAuth } from "services/index.js";

import { addCoupon } from "services";
import { editCouponStatus } from "services";
import { DataTable } from "../HOC/DataTable";
import { Pagination, Input, Spin, Empty, Row, Col, Select } from "antd";
import { SearchOutlined } from '@ant-design/icons';

const { Option } = Select;

const customStyles = {
  content: {
    width: '50%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 100,
    backgroundImage: "white"
  }
};

class Coupon extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedActions: [],
      user_id: null,
      loading: false,
      newCouponPopup: false,
      code: null,
      amount: null,
      type: null,
      qty: null,
      total_qty: null,
      start_at: null,
      expire_at: null,
      Ranges: []
    };
  }

  componentDidMount() { }

  addCoupon = async () => {
    const { code, amount, qty, total_qty, start_at, expire_at } = this.state;
    if (!code || !amount || !qty || !start_at || !expire_at) {
      return;
    }

    const type = document.getElementById("coupon-type");
    const visibility = document.getElementById("coupon-visibility");
    if (type.selectedIndex != null && type.selectedIndex >= 0 && visibility.selectedIndex != null && visibility.selectedIndex >= 0) {
      this.setState({ loading: true });
      const response = await addCoupon({ code, amount, type: type.value, visible:parseInt(visibility.value), qty, total_qty, start_at, expire_at });
      if (response && response.status === "success") {
        this.setState({ loading: false, newCouponPopup: false, code: null, amount: null, qty: null, total_qty: null, start_at: null, expire_at: null });
        this.props.getList();
      }
    }

  }

  editCouponStatus = async (id, status) => {
    if (!id || !status) {
      return;
    }
    this.setState({ loading: true });
    const response = await editCouponStatus(id, status);
    if (response && response.status === "success") {
      this.props.getList();
    }
  }

  keywordSearch = (e) => {
    const { Ranges } = this.state;
    const { setFieldValue, search } = this.props;
    setFieldValue({ keywords: e.target.value })
    Ranges.length && setFieldValue({ category: Ranges })
    search();
  }
  handleRangeSelect = (Ranges) => {
    this.setState({ Ranges })
  }

  render() {
    const { pageChange, total, page: { current, pageSize }, dataList, pageSizeOptions, loading } = this.props;
    return (
      <>
        {/* <Header /> */}
        <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
          <Container fluid>
            <div className="header-body"><h1>Coupons</h1></div>
            <button onClick={() => this.setState({ newCouponPopup: true })}>Add new coupon</button>
          </Container>
        </div>
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Modal
            isOpen={this.state.newCouponPopup}
            onAfterOpen={() => { }}
            onRequestClose={() => { }}
            style={customStyles}
            contentLabel="new actions"
          >
            <div style={{ textAlign: "center" }}>
              <h2 style={{ textAlign: "center", marginBottom: "10px" }}> Please enter the coupon code </h2>
              <input style={{ textAlign: "center", width: "80%" }} value={this.state.code} onChange={(e) => this.setState({ code: e.target.value })} />
              {this.state.userlist && this.state.userlist.map(item =>
                <div style={{ cursor: "pointer" }} onClick={() => this.setState({ targetUser: item, searchWord: item.username })}>{item.username}</div>
              )}
              <h2 style={{ textAlign: "center", marginBottom: "10px" }}> Please enter the amount </h2>
              <input style={{ textAlign: "center" }} type="number" value={this.state.amount ? this.state.amount / 100 : null} onChange={(e) => this.setState({ amount: Math.round(e.target.value) * 100 })} />
              <h2 style={{ textAlign: "center", marginBottom: "10px" }}> Please select the type </h2>
              <div style={{ textAlign: "center", marginBottom: "20px" }}>
                <select id="coupon-type" className="popup-select" name="coupon-type">
                  <option value="per ac">per ac</option>
                </select>
              </div>
              <h2 style={{ textAlign: "center", marginBottom: "10px" }}> Please select visibility </h2>
              <div style={{ textAlign: "center", marginBottom: "20px" }}>
                <select id="coupon-visibility" className="popup-select" name="coupon-visibility">
                  <option value="0">Private</option>
                  <option value="1">Public</option>
                </select>
              </div>
              <h2 style={{ textAlign: "center", marginBottom: "10px" }}> Please enter the qty per account</h2>
              <input style={{ textAlign: "center" }} type="number" min="0" value={this.state.qty} onChange={(e) => this.setState({ qty: e.target.value })} />
              <h2 style={{ textAlign: "center", marginBottom: "10px" }}> Please enter the total qty </h2>
              <input style={{ textAlign: "center" }} type="number" min="0" value={this.state.total_qty} onChange={(e) => this.setState({ total_qty: e.target.value })} />
              <h2 style={{ textAlign: "center", marginBottom: "10px" }}> Please select the start date </h2>
              <input style={{ textAlign: "center" }} type="date" value={this.state.start_at} onChange={(e) => this.setState({ start_at: e.target.value })} />
              <h2 style={{ textAlign: "center", marginBottom: "10px" }}> Please select the expire date </h2>
              <input style={{ textAlign: "center" }} type="date" value={this.state.expire_at} onChange={(e) => this.setState({ expire_at: e.target.value })} />
              {/*
            <h2 style={{textAlign:"center", marginBottom:"10px"}}> Please enter the reamrk </h2>
            <textarea id="w3review" name="w3review" rows="3" cols="50" onChange = {(e)=>this.setState({remark:e.target.value})}></textarea>
            */}

            </div>
            {this.state.loading ?
              <h3 style={{ textAlign: "center", color: "red" }}>Loading, please wait</h3>
              :
              <div style={{ marginTop: "10px" }}>
                <button style={{ cursor: "pointer", backgroundColor: "white", border: "1px solid black", borderRadius: "12px", width: "20%", marginLeft: "15%", marginRight: "15%" }} onClick={() => this.setState({ newCouponPopup: false, code: null, amount: null, type: null, qty: null, total_qty: null })}>Back</button>
                <button style={{ cursor: "pointer", backgroundColor: "white", border: "1px solid black", borderRadius: "12px", width: "20%", marginLeft: "15%", marginRight: "15%" }} onClick={() => this.addCoupon()}>Confirm</button>
              </div>
            }
          </Modal>
          {/* Table */}
          <Card className="shadow tableBox">
            <Row className='mt24 mb24 ml12' gutter={[16, 16]}>
              {/* <Col span={8}>
                <label>搜索范围：</label>
                <Select
                  mode="multiple"
                  allowClear
                  size="large"
                  placeholder="Please select"
                  onChange={this.handleRangeSelect}
                  className="rangesStyle"
                >
                  <Option value="username">USERNAME</Option>
                  <Option value="ref_no">REF NO</Option>
                </Select>
              </Col> */}
              <Col span={8}>
                <label>关键字：</label>
                <Input onPressEnter={this.keywordSearch} size="large" placeholder="keyword" prefix={<SearchOutlined />} />
              </Col>
            </Row>
            <Table className="align-items-center table-flush" responsive>
              <thead className="thead-light">
                <tr>
                  <th scope="col">CODE</th>
                  <th scope="col">AMOUNT (HKD)</th>
                  <th scope="col">TYPE</th>
                  <th scope="col">VISIBILITY</th>
                  <th scope="col">QTY</th>
                  <th scope="col">TOTAL QTY</th>
                  <th scope="col">STATUS</th>
                  <th scope="col">START AT</th>
                  <th scope="col">EXPIRE AT</th>
                  <th scope="col">CREATED AT</th>
                  <th scope="col">UPDATED AT</th>
                </tr>
              </thead>
              <tbody>
                {
                  !loading && dataList.length ?
                    <>
                      {dataList.map(item =>
                        <tr key={item.id}>
                          <td>{item.code}</td>
                          <td>{priceHandler(item.amount)}</td>
                          <td>{item.type}</td>
                          <td>{item.visible==0?"Private":"Public"}</td>
                          <td>{item.qty}</td>
                          <td>{item.total_qty===null?"Unlimit":item.total_qty}</td>
                          <td>
                            <UncontrolledDropdown>
                              <DropdownToggle caret color="secondary">
                                {item.status === "closed" ?
                                  <span style={{ color: "red" }}>Closed</span> :
                                  <span style={{ color: "green" }}>Live</span>
                                }
                              </DropdownToggle>
                              <DropdownMenu>
                                <DropdownItem href="#pablo" onClick={(e) => this.editCouponStatus(item.id, "live")}>
                                  <span style={{ color: "green" }}>Live</span>
                                </DropdownItem>
                                <DropdownItem href="#pablo" onClick={(e) => this.editCouponStatus(item.id, "closed")}>
                                  <span style={{ color: "red" }}>Closed</span>
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td>
                          <td>{item.start_at}</td>
                          <td>{item.expire_at}</td>
                          <td>{item.created_at}</td>
                          <td>{item.updated_at}</td>
                        </tr>
                      )}
                    </>
                    :
                    <tr className="table_loading">
                      {/* 10对应的是表头的数量，不然不居中 */}
                      <td colSpan={10}>
                        {
                          loading ?
                            <div className="textCenter">
                              <Spin spinning={true} />
                            </div>
                            : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        }
                      </td>
                    </tr>
                }
              </tbody>
            </Table>
            <div className="mt20 mb20">
              {
                dataList.length ?
                  <Pagination
                    showSizeChanger
                    pageSizeOptions={pageSizeOptions}
                    onChange={pageChange}
                    current={current}
                    pageSize={pageSize}
                    total={total}
                  /> : null
              }
            </div>
          </Card>
        </Container>
      </>
    );
  }
}

export default withAuth(DataTable(getAllCoupon)(Coupon));
