import { Modal } from 'antd';

let flag = true;// 防止连续出现多个用户登录超时的提示

export default function logOut (text) {
  if (flag) {
    flag = false;
    Modal.info({
        title: 'Login expired, please login again',
        okText:"Login again",
        onOk() {
            localStorage.removeItem('token')
            sessionStorage.clear();
            window.location.reload();
            flag = true
        },
    });
  }
}